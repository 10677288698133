<template>
  <div class="bg-white">
    <q-layout
      view="hHh LpR fFf"
      class="bg-white"
    >
      <router-component name="appbar" :fallback="appbar" />
      <component :is="sidebar" v-if="$q.platform.is.desktop" />
      <q-page-container>
        <q-page>
          <transition>
            <router-view name="default" />
          </transition>
        </q-page>
        <router-component name="bottom" :fallback="footer" />
      </q-page-container>
      <router-view name="right" />
      <m-booking-complete />
    </q-layout>
  </div>
</template>

<script>
import _ from 'lodash'
import { MBookingComplete } from 'components'
import { mapGetters } from 'vuex'
import authentication from 'mixins/authentication.js'
import routerComponent from '../mobile/router-component/index.vue'

export default {
  name: 'LayoutBasic',
  components: {
    sidebar: () => import('./sidebar.vue'),
    sidebarBackoffice: () => import('./sidebar-backoffice.vue'),
    routerComponent,
    MBookingComplete
  },
  mixins: [authentication],
  data () {
    return {
      dismiss: null,
      terms: '',
      error: false
    }
  },
  computed: {
    ...mapGetters(['ui']),
    right () {
      const route = _.findLast(this.$route.matched, n => {
        return !!n.components.right
      })
      return route ? route.components.right : null
    },
    sidebar () {
      return this.hasRole(['backoffice', 'admin']) ? 'sidebar-backoffice' : 'sidebar'
    },
    appbar () {
      return this.$q.platform.is.desktop ? 'MDashAppbar' : 'MMobileBackButton'
    },
    footer () {
      return this.$q.platform.is.desktop ? '' : 'MMobileFooter'
    }
  },
  watch: {
    '$route.hash': {
      immediate: true,
      handler (value, old) {
        if (value !== '') {
          document.body.classList.add('no-scroll')
        } else {
          document.body.classList.remove('no-scroll')
        }
      }
    },
    '$route.path': {
      handler () {
        this.error = false
      }
    }
  },
  beforeMount () {
    this.$store.dispatch('geolocate/get')
  },
  mounted () {
    document.body.classList.add('bg-white')
  }
}
</script>
<style lang="stylus" scoped>
.q-page-container
  position fixed
  inset 0px
  overflow-y auto
  bottom -15px

.option-button
  position relative
  pointer-events none
  opacity 1
  .q-btn
    position absolute
    bottom 50%
    pointer-events all

.q-tabs >>> .q-tabs__content
  overflow visible!important

.q-footer--hidden
  .option-button .q-btn
    display none

@media (max-width $breakpoint-xs-max)
  .q-tab
    padding 0 12px
</style>
